<script>
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    modal: {
      type: Boolean,
      default: false
    },
  },
  components: {
  },
  data() {
    return {
      loading: false,
      errored: '',

      client_name: '',
      store_id: '',
      store_number: '',
    };
  },
  validations: {
    client_name: { required },
    store_id: {},
    store_number: {},
  },
  methods: {
    criarPedido() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;

        api
          .post("pedidos", {
            client_name: this.client_name,
            store_id: this.store_id,
            store_number: this.store_number,
          })
          .then((res) => {
            if (res.data.status == "success") {
              this.client_name = "";
              this.store_id = "";
              this.store_number = "";
              this.$v.$reset();

              this.$router.push('/pedidos/' + res.data.order.id);
            } else {
              this.$toast.error(res.data.message);
            }

            this.loading = false;
          })
          .catch((error) => {
            this.errored = error;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<template>
  <div>
    <b-modal v-model="modal" modal-class="modal-right" hide-header hide-footer>
      <div>
        <h4 class="mb-5">Criar pedido</h4>
        <b-form @submit.prevent="criarPedido">
          <b-form-group id="client_name" label="Nome do Cliente" label-for="client_name" class="required">
            <b-form-input id="client_name" v-model="client_name" type="text" :class="{ 'is-invalid': $v.client_name.$error }"></b-form-input>
            <div v-if="$v.client_name.$error" class="invalid-feedback">
              <span v-if="!$v.client_name.required">O nome do cliente é obrigatório.</span>
            </div>
          </b-form-group>
          <b-form-group id="store_id" label="Loja" label-for="store_id">
            <select v-model="store_id" class="form-control" :class="{ 'is-invalid': $v.store_id.$error }">
              <option value=""></option>
              <option value="shopee">Shopee</option>
            </select>
          </b-form-group>
          <b-form-group id="store_number" label="Número do Pedido" label-for="store_number">
            <b-form-input id="store_number" v-model="store_number" type="text" :class="{ 'is-invalid': $v.store_number.$error }"></b-form-input>
          </b-form-group>
          <div class="mt-4 d-flex justify-content-between">
            <b-button :disabled="this.loading == true || this.$v.$invalid" type="submit" variant="default">
              Criar
              <b-spinner v-if="loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
            </b-button>
            <a class="btn btn-outline-link" v-on:click="modal = false;">Fechar <sup>ESC</sup></a>
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>